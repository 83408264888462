<template>
  <div>
    <mds-section
      :bold="true"
      title="Add Custom Embed"
      class="section"
    ></mds-section>
    <p>
      To see list of supported embeds by Morningstar, click
      <a
        class="link"
        :href="arcSupportedEmbeds"
        target="_blank"
        >here</a
      >
    </p>
    <mds-form v-show="!isUpdating" size="medium" onsubmit="return false">
      <mds-input
        v-model="embedUrl"
        size="medium"
        required
        label="Custom Embed"
        placeholder="Paste Embed url"
        :error-text="['Invalid input']"
        :error="isEmbedUrlInvalid"
        class="url-input"
      ></mds-input>
      <mds-button
        type="button"
        @click="submit"
        variation="primary"
        :disabled="isEmbedUrlInvalid"
      >
        Add
      </mds-button>
    </mds-form>
    <mds-loader v-show="isUpdating" aria-label="Medium Loader"></mds-loader>
  </div>
</template>

<script>
import Vue from 'vue';
import MdsForm from '@mds/form';
import MdsInput from '@mds/input';
import { MdsButton } from '@mds/button';
import MdsSection from '@mds/section';
import MdsLoader from '@mds/loader';
import { sendDataMessage } from '@/js/powerup';
import Request from '@/js/request.js';

const arcSupportedEmbeds = "https://redirector.arcpublishing.com/alc/arc-products/ellipsis/user-docs/ellipsis-supported-oembeds/";

export default Vue.extend({
  name: 'OembedSearchEditComponent',
  components: {
    MdsForm,
    MdsInput,
    MdsButton,
    MdsSection,
    MdsLoader,
  },
  data() {
    return {
      embedUrl: '',
      disableSubmit: true,
      isUpdating: false,
      editMode: false,
      k: 'k',
      p: 'p',
      finalObject: { id: '', url:'', config: {} },
      gatewayURI: process.env.VUE_APP_GATEWAY_URL + "customoembed",
    };
  },
  computed: {
    isEmbedUrlInvalid: function () {
      return this.embedUrl.length == 0 || !this.isUrlValid(this.embedUrl);
    },
  },
  methods: {
    submit: async function () {

      // loading spinner
      this.isUpdating = true;

      // making request to the backend to get oembed data
      const response = await Request.get(
        `${this.gatewayURI}?url=${this.embedUrl}`
      );

      console.log(response);
      if(response.hasOwnProperty("type")) {
        delete response.type;
      }
      if(response.hasOwnProperty("version")) {
        delete response.version;
      }

      // stop loading spinner
      this.isUpdating = false;

      // assign oembed url
      this.finalObject.id=this.embedUrl;
      this.finalObject.url=this.embedUrl;
      this.finalObject.config = response;
      
      sendDataMessage(new URL(location.href).pathname, this.finalObject);
    },
    isUrlValid: function (str) {
      return /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/.test(
        str,
      );
    },
  },
  created: async function () {
    // check edit mode
    if (this.$route.query.debug !== undefined) {
      if (this.$route.query.debug === 'true') {
        this.debug = true;
        log('DEBUG is on.', 'info');
      }
    }
    if (this.debug) log('created', 'life');
    if (this.debug) log(`route: ${this.$route.path}`, 'debug');
    if (this.$route.path === '/oembed/edit') {
      if (this.debug) log('this.edit == true');
      this.edit = true;
    }

    // check for k
    if (this.$route.query.k === undefined) {
      if (this.debug)
        log('query does not contain the proper query identifier (k)', 'warn');
    } else {
      this.k = this.$route.query.k;
    }

    // if page is edit, check for p
    if (this.editMode && this.$route.query.p === undefined) {
      if (this.debug)
        log(
          'query does not contain the proper query identifier for /oembed/edit/ (p)',
          'warn',
        );
    } else {
      this.p = this.$route.query.p;
    }
  },
  beforeMount: async function () {
    if (this.debug) log('beforeMount', 'life');
    if (this.p) {
      let query = JSON.parse(this.p);
      this.embedUrl = query.url;
    }
  },
});
</script>

<style lang="scss" scoped>
@import '@mds/typography';
@import '@mds/link';
.section {
  margin-bottom: $mds-space-4-x;
}
.url-input {
  width: 40em;
}
p {
  @include mds-body-text-m();
}
.link {
  @include mds-link();
}
</style>
