<template>
  <main ref="view">
    <mds-section id="viewSection">
      <iframe
        :src="embedUrl"
        frameborder="0"
        style="width: 0; min-width: 100% !important; border: none"
        :height="height"
      ></iframe>
    </mds-section>
  </main>
</template>

<script>
import Vue from 'vue';
import { log, sendReadyMessage, sendCancelMessage } from '@/js/powerup.js';
import MdsSection from '@mds/section';

export default Vue.extend({
  name: 'OembedViewComponent',
  components: {
    MdsSection,
  },
  data() {
    return {
      p: Object,
      k: Number,
      id: '',
      embedUrl: '',
      height: 500,
    };
  },
  methods: {
    cancel() {
      sendCancelMessage(new URL(location.href).pathname);
    },
    normalizeData() {
      try {
        let query = JSON.parse(this.$route.query.p);
        this.id = query.id;
        this.embedUrl = query.url;
      } catch (error) {
        if (this.debug) log('The URL was not parsed properly', 'error', error);
        this.cancel();
      }
    },
  },
  created: async function () {
    if (this.$route.query.debug !== undefined) {
      if (this.$route.query.debug === 'true') {
        log('created', 'life');
        this.debug = true;
        log('DEBUG is on.', 'info');
        log('this.$route.query: ', 'dir', this.$route.query);
      }
    }

    // check for p and k
    if (
      this.$route.query.p === undefined ||
      this.$route.query.k === undefined
    ) {
      if (this.debug)
        log('query does not contain the proper query identifiers', 'error');
      this.cancel();
    } else {
      this.p = this.$route.query.p;
      this.k = this.$route.query.k;
      await this.normalizeData();
    }
  },
  mounted: function () {
    if (this.debug) log('mounted', 'life');
    this.height = this.$refs.view.scrollHeight;
    sendReadyMessage(new URL(location.href).pathname, this.height);
  },
});
</script>

<style lang="scss" scoped></style>
